import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { handleShortUrl } from "@/services/handleShortUrl";
import { handleStartConversationEmbed } from "@/services/handleStartConversationEmbed";
import { getAuth } from "firebase/auth";
import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/HomePage.vue"),
    meta: { title: "PatientGenie" },
  },
  {
    path: "/providers-business",
    name: "ProvidersBusiness",
    component: () => import("@/views/ProvidersBusinessPage.vue"),
    meta: { title: "Patientgenie for providers" },
  },
  {
    path: "/health-systems-business",
    name: "HealthSystemsBusiness",
    component: () => import("@/views/HealthSystemsBusinessPage.vue"),
    meta: { title: "Patientgenie for health systems" },
  },
  {
    path: "/employers-and-plans-business",
    name: "EmployersAndPlansBusiness",
    component: () => import("@/views/EmployersAndPlansBusinessPage.vue"),
    meta: { title: "Patientgenie for employers and health plans" },
  },
  // {
  //   path: "/plans-and-prices",
  //   name: "PlansAndPrices",
  //   component: () => import("@/views/PlansAndPricesPage.vue"),
  //   meta: { title: "Pricing" },
  // },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/AboutPage.vue"),
    meta: { title: "About patientgenie" },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("@/views/PrivacyPolicyPage.vue"),
    meta: { title: "Patientgenie privacy policy" },
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("@/views/TermsPage.vue"),
    meta: { title: "Patientgenie terms of service" },
  },
  {
    path: "/notices",
    name: "Notices",
    component: () => import("@/views/NoticesPage.vue"),
    meta: { title: "Patientgenie notices" },
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: () => import("@/views/ContactUsPage.vue"),
    meta: { title: "Contact patientgenie" },
  },
  {
    path: "/news",
    name: "NewsList",
    component: () => import("@/views/NewsListPage.vue"),
    meta: { title: "Patientgenie news" },
  },
  {
    path: "/news/:id",
    name: "NewsItem",
    component: () => import("@/views/NewsItemPage.vue"),
    meta: { title: "Patientgenie news" },
  },
  {
    path: "/careers",
    name: "Jobs",
    component: () => import("@/views/JobsPage.vue"),
    meta: { title: "Patientgenie careers" },
  },
  {
    path: "/careers/:jobSlug",
    name: "JobPosition",
    component: () => import("@/views/JobPositionPage.vue"),
    meta: { title: "Patientgenie careers" },
  },
  {
    path: "/l/:id",
    beforeEnter: handleShortUrl,
    meta: {
      title: "Patientgenie",
      allowEmbed: true,
    },
  },
  {
    path: "/start-conversation-embed",
    beforeEnter: handleStartConversationEmbed,
    meta: { title: "Patientgenie", allowEmbed: true },
  },
  {
    path: "/embed-full",
    name: "EmbedFull",
    component: () => import("@/views/FullEmbedPage.vue"),
    meta: { title: "PatientGenie", allowEmbed: true },
  },
  {
    path: "*",
    component: () => import("@/views/HomePage.vue"),
    meta: { title: "PatientGenie" },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
            behavior: "smooth",
            offset: { x: 0, y: 50 },
          });
        }, 350);
      });
    }

    if (from.path === to.path) {
      return;
    }

    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  store.commit("patientProfile/SET_ACTIVE_NAV_ITEM", to.name);

  const { currentUser } = getAuth();

  if (to.meta?.requiresAuth && !currentUser) {
    next({ name: "Login" });
  }

  if (to.meta?.allowEmbed) {
    next();
    return;
  }

  if (Vue.prototype.$appConfig.isEmbed) {
    router.replace({ name: "EmbedFull" });
    return;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const isStartNewConversation = urlParams.get("cmd") === "startConversation";

  if (process.env.VUE_APP_INTEGRATION_URL && !isStartNewConversation) {
    window.location.replace(process.env.VUE_APP_INTEGRATION_URL);
  }

  next();
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta?.title || "PatientGenie";
  });
});

export default router;
