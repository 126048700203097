<template>
  <div class="business-drawer" data-testid="business-drawer">
    <nav>
      <ul class="nav-list">
        <li class="nav-item">
          <router-link :to="{ path: '/providers-business' }">Practices</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ path: '/health-systems-business' }">Health Systems</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ path: '/employers-and-plans-business' }">
            Employers & Plans
          </router-link>
        </li>
      </ul>
    </nav>

    <nav class="website-nav">
      <div v-for="(item, index) in navItems" :key="index">
        <template v-if="item.accordionItems && item.accordionItems.length">
          <div class="accordion-item-wrapper" @click="item.isAccordionOpen = !item.isAccordionOpen">
            <button class="item">{{ item.title }}</button>

            <v-icon color="var(--color-grey-primary)">
              {{ item.isAccordionOpen ? mdiChevronUp : mdiChevronDown }}
            </v-icon>
          </div>

          <div class="accordion-content" v-show="item.isAccordionOpen">
            <router-link
              v-for="(accordionItem, k) in item.accordionItems"
              :key="k"
              :to="{ path: accordionItem.path, hash: accordionItem.hash }"
              class="accordion-item"
            >
              {{ accordionItem.title }}
            </router-link>
          </div>
        </template>
        <router-link v-else :to="{ path: item.path, hash: item.hash }" class="item">
          {{ item.title }}
        </router-link>
      </div>
    </nav>

    <div class="auth-actions">
      <AppButton
        height="50"
        data-testid="business-drawer-login-link"
        class="auth-action-link"
        @click="handleTryNowClick"
      >
        Try now
      </AppButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { mdiChevronUp, mdiChevronDown } from "@mdi/js";
import { AppButton } from "pg-ui";
import openNewTab from "@/services/openNewTab";

const navItems = ref([
  {
    title: "About",
    isAccordionOpen: false,
    accordionItems: [
      { title: "About us", path: "/about" },
      { title: "Leadership", path: "/about", hash: "#leadership" },
      { title: "News", path: "/news" },
      { title: "Careers", path: "/careers" },
      { title: "Privacy policy", path: "/privacy" },
      { title: "Terms of service", path: "/terms" },
      { title: "Notices", path: "/notices" },
      { title: "Contact", path: "/contact-us" },
    ],
  },
]);

function handleTryNowClick() {
  openNewTab(process.env.VUE_APP_ASK_WEBSITE_URL!);
}
</script>

<style lang="scss" scoped>
.business-drawer {
  display: flex;
  flex-direction: column;
  height: 100%;

  .nav-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0 -16px;
    padding: 0 16px 24px;
    border-bottom: 1px solid var(--color-grey-secondary);

    .nav-item {
      a:not(.demo-link) {
        display: block;
        padding: 12px 0;
        color: var(--color-grey-primary);
        font-weight: var(--font-weight-bolder);
      }

      .demo-link {
        height: 40px;
        margin: 12px 0;
        color: var(--color-white);
        border-radius: 8px;

        &::v-deep {
          .button-text {
            display: flex;
            gap: 8px;
            align-items: center;
            font-size: var(--font-medium);
          }
        }
      }
    }
  }

  .website-nav {
    padding: 24px 0;
  }

  .accordion-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item {
      padding: 12px 0;
      font-weight: var(--font-weight-bolder);
    }
  }

  .accordion-content {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .accordion-item {
      display: block;
      padding: 12px 0;
      color: var(--color-grey-primary);
      font-weight: var(--font-weight-medium);
    }
  }

  .auth-actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: auto;
    padding-top: 44px;

    .auth-action-link {
      border-radius: 8px;

      &::v-deep {
        // stylelint-disable-next-line
        .button-text {
          color: var(--color-white);
        }
      }
    }
  }
}
</style>
