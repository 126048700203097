<template>
  <v-navigation-drawer
    v-model="isDrawerOpened"
    class="drawer"
    overlay-color="var(--color-grey-secondary)"
    :color="getDrawerColor"
    width="320px"
    right
    fixed
    temporary
    touchless
  >
    <section class="content" data-testid="nav-drawer-content">
      <div class="drawer-header">
        <button
          aria-label="drawer close button"
          data-testid="drawer-close-btn"
          class="close-btn"
          @click="isDrawerOpened = false"
        >
          <v-icon size="32" color="var(--color-grey-primary)">
            {{ mdiClose }}
          </v-icon>
        </button>
      </div>

      <div class="drawer-body">
        <component :is="getDrawerContentComponent" />
      </div>
    </section>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { mdiClose } from "@mdi/js";
import BusinessDrawer from "@/components/Drawer/BusinessDrawer.vue";

export default Vue.extend({
  name: "TheDrawer",
  components: {
    BusinessDrawer,
  },
  data() {
    return {
      mdiClose,
      isDrawerOpened: false,
    };
  },
  computed: {
    ...mapGetters({
      getIsDrawerOpened: "getIsDrawerOpened",
    }),
    getDrawerColor(): string {
      return "var(--color-white)";
    },
    getDrawerContentComponent(): string {
      return "BusinessDrawer";
    },
  },
  watch: {
    getIsDrawerOpened(value) {
      this.isDrawerOpened = value;
    },
    isDrawerOpened(value) {
      this.setIsDrawerOpened(value);
    },
  },
  methods: {
    ...mapActions(["setIsDrawerOpened"]),
    async handleTabClick(route: { name: string }) {
      await this.$router.push({ name: route.name });
      this.isDrawerOpened = true;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

.drawer::v-deep {
  box-shadow: 0 10px 25px 0 rgb(7 18 46 / 0.1);

  &.v-navigation-drawer {
    transition-duration: 0.3s;
  }

  & .v-navigation-drawer__content {
    display: flex;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding: 12px 16px;
}

.drawer-header {
  margin-bottom: 28px;

  .close-btn {
    display: block;
    margin-left: auto;
  }
}

.drawer-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tabs {
  display: flex;
  gap: 16px;
  margin: 0 -16px 28px;
  padding: 6px 16px;
  border-bottom: 2px solid var(--color-grey-secondary);

  .tab-link {
    position: relative;
    color: #75777d;
    font-weight: var(--font-weight-bolder);

    &::before {
      position: absolute;
      bottom: -8px;
      display: none;
      width: 100%;
      height: 2px;
      background-color: var(--color-purple-primary);
      content: "";
    }

    &.active {
      color: var(--color-grey-primary);

      &::before {
        display: block;
      }
    }
  }
}
</style>
