var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',{class:[
    { 'footer-default': _vm.isDefaultFooter },
    { conversation: _vm.isConversationPage },
    { 'white-bg': _vm.isWhiteBg },
  ]},[(_vm.isDefaultFooter)?[_c('div',{staticClass:"content"},[_vm._m(0),_c('nav',{staticClass:"links"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ path: '/' }}},[_vm._v(" Overview ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ path: '/providers-business' }}},[_vm._v("Practices")])],1),_c('li',[_c('router-link',{attrs:{"to":{ path: '/health-systems-business' }}},[_vm._v("Health Systems")])],1),_c('li',[_c('router-link',{attrs:{"to":{ path: '/employers-and-plans-business' }}},[_vm._v(" Employers & Plans ")])],1)]),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ path: '/about' }}},[_vm._v("About us")])],1),_c('li',[_c('router-link',{attrs:{"to":{ path: '/about', hash: '#leadership' }}},[_vm._v("Leadership")])],1),_c('li',[_c('router-link',{attrs:{"to":{ path: '/careers' }}},[_vm._v("Careers")])],1),_c('li',[_c('router-link',{attrs:{"to":{ path: '/news' }}},[_vm._v("News")])],1)]),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ path: '/privacy' }}},[_vm._v("Privacy policy")])],1),_c('li',[_c('router-link',{attrs:{"to":{ path: '/terms' }}},[_vm._v("Terms of service")])],1),_c('li',[_c('router-link',{attrs:{"to":{ path: '/notices' }}},[_vm._v("Notices")])],1),_c('li',[_c('router-link',{attrs:{"to":{ path: '/contact-us' }}},[_vm._v("Contact")])],1)])]),_vm._m(1)]),_c('div',{staticClass:"copy"},[_vm._v("© PatientGenie, Inc. "+_vm._s(_vm.currentYear))])]:[_vm._v(" © PatientGenie, Inc. "+_vm._s(_vm.currentYear))]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"logo-wrapper"},[_c('img',{attrs:{"src":"/img/logo-white.svg","width":"46","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"about"},[_c('p',{staticClass:"about-description"},[_c('span',{staticClass:"about-description-title"},[_vm._v("PatientGenie")]),_vm._v(" is the AI health agent, powered by Generative AI and a patented data engine, that assists patients in navigating the complexities of provider searches. Integrated with dozens of data and technology partners, all seamlessly intertwined with solutions such as triage, patient appointments, insurance availability, telehealth, price transparency and provider’s directory in a single platform, PatientGenie becomes a healthcare marketplace that enables all other care delivery companies. ")])])
}]

export { render, staticRenderFns }