<template>
  <footer
    :class="[
      { 'footer-default': isDefaultFooter },
      { conversation: isConversationPage },
      { 'white-bg': isWhiteBg },
    ]"
  >
    <template v-if="isDefaultFooter">
      <div class="content">
        <div class="logo-wrapper">
          <img src="/img/logo-white.svg" width="46" alt="" />
        </div>
        <nav class="links">
          <ul>
            <li>
              <router-link :to="{ path: '/' }"> Overview </router-link>
            </li>
            <!--            <li>-->
            <!--              <router-link :to="{ path: '/plans-and-prices' }"> Pricing </router-link>-->
            <!--            </li>-->
            <li>
              <router-link :to="{ path: '/providers-business' }">Practices</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/health-systems-business' }">Health Systems</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/employers-and-plans-business' }">
                Employers & Plans
              </router-link>
            </li>
          </ul>
          <ul>
            <li>
              <router-link :to="{ path: '/about' }">About us</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/about', hash: '#leadership' }">Leadership</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/careers' }">Careers</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/news' }">News</router-link>
            </li>
          </ul>
          <ul>
            <li>
              <router-link :to="{ path: '/privacy' }">Privacy policy</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/terms' }">Terms of service</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/notices' }">Notices</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/contact-us' }">Contact</router-link>
            </li>
          </ul>
        </nav>
        <div class="about">
          <p class="about-description">
            <span class="about-description-title">PatientGenie</span> is the AI health agent,
            powered by Generative AI and a patented data engine, that assists patients in navigating
            the complexities of provider searches. Integrated with dozens of data and technology
            partners, all seamlessly intertwined with solutions such as triage, patient
            appointments, insurance availability, telehealth, price transparency and provider’s
            directory in a single platform, PatientGenie becomes a healthcare marketplace that
            enables all other care delivery companies.
          </p>
        </div>
      </div>
      <div class="copy">© PatientGenie, Inc. {{ currentYear }}</div>
    </template>
    <template v-else> © PatientGenie, Inc. {{ currentYear }}</template>
  </footer>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "TheFooter",
  props: {
    isDefaultFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    isConversationPage(): boolean {
      return this.$route.name === "Conversation";
    },
    isWhiteBg(): boolean {
      return (
        this.$route.meta?.isAuthRoute ||
        this.$route.name === "PatientProfile" ||
        this.$route.name === "ProviderProfile"
      );
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

footer {
  bottom: 0;
  width: 100%;
  padding: 44px 0 24px;
  color: var(--color-grey-primary);
  text-align: center;
  background: transparent;

  &.white-bg {
    background-color: var(--color-white);

    @include breakpoint-reverse(xsmall) {
      background-color: transparent;
    }
  }

  &.footer-default {
    text-align: left;
    background-color: var(--color-purple-primary);

    .content {
      display: grid;
      grid-template-areas: "logo links about";
      grid-template-columns: 50px 1fr 0.7fr;
      gap: 100px;
      max-width: 1920px;
      margin: 0 auto;
      padding: 0 44px;
      color: var(--color-white);

      @include breakpoint(mdlarge) {
        grid-template-columns: auto auto auto;
        gap: 50px;
      }

      @include breakpoint(large) {
        gap: 44px;
      }

      @include breakpoint(medium) {
        padding: 0 50px;
      }

      @include breakpoint(small) {
        grid-template-areas:
          "logo logo logo"
          "links links about";
        grid-template-columns: 1fr 1fr 1fr;
      }

      @include breakpoint(xsmall) {
        grid-template-areas:
          "logo"
          "links"
          "about";
        grid-template-columns: 1fr;
        gap: 24px;
        padding: 0 20px;
      }
    }

    .logo-wrapper {
      grid-area: logo;

      @include breakpoint(xsmall) {
        margin: 0 auto;
      }
    }

    .links {
      display: grid;
      grid-area: links;
      grid-template-columns: 1fr 1fr auto auto;
      gap: 44px;

      @include breakpoint(xsmall) {
        grid-template-columns: 1fr;
        gap: 16px;
        text-align: center;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: 0;
        padding: 0;
        font-size: var(--font-medium);

        &:last-child {
          @include breakpoint(xsmall) {
            margin-top: -16px;
          }
        }

        a {
          color: var(--color-grey-secondary);
        }
      }
    }

    .empty-title {
      @include breakpoint(xsmall) {
        display: none;
      }
    }

    .links-title {
      font-weight: var(--font-weight-bolder);
      font-size: var(--font-extra-small);
      text-transform: uppercase;
    }

    .about {
      grid-area: about;

      .about-description {
        max-width: 520px;
        margin-left: auto;
        color: var(--color-grey-secondary);
        font-size: var(--font-medium);

        &-title {
          color: var(--color-white);
          font-weight: var(--font-weight-bolder);
        }

        @include breakpoint(small) {
          max-width: unset;
        }
      }
    }

    .copy {
      margin-top: 44px;
      color: var(--color-white);
      text-align: center;

      @include breakpoint(xsmall) {
        margin-top: 14px;
      }
    }
  }

  &.conversation {
    @include breakpoint(xsmall) {
      display: none;
    }
  }
}
</style>
