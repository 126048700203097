<template>
  <BusinessHeader />
</template>

<script lang="ts">
import Vue from "vue";
import BusinessHeader from "@/components/Header/BusinessHeader.vue";

export default Vue.extend({
  name: "TheHeader",
  components: {
    BusinessHeader,
  },
});
</script>
