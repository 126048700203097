<template>
  <v-app v-if="!featuresLoading && !tokenLoading" id="app" class="app">
    <template>
      <TheHeader />

      <TheDrawer />

      <v-main class="view-wrapper" :style="isDefaultFooter ? `min-height: 100vh;` : ''">
        <transition mode="out-in" name="fade">
          <router-view />
        </transition>
      </v-main>

      <TheFooter :is-default-footer="isDefaultFooter" />
    </template>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import { getCookie } from "@/services/cookieService";
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheDrawer from "@/components/TheDrawer.vue";

export default Vue.extend({
  name: "App",
  components: {
    TheHeader,
    TheFooter,
    TheDrawer,
  },
  data() {
    return {
      currentAuthToken: this.$appConfig.isEmbed
        ? localStorage.getItem("authToken") || ""
        : getCookie("token") || "",
      urlKeyword: null as null | string,
      urlPath: null as null | string,
      swRefreshing: false,
      swRegistration: null as null | any,
      swUpdateExists: false,
      mapRoutes: ["Maps"],
      tokenLoading: false,
      featuresLoading: false,
    };
  },
  computed: {
    isAuthRoute(): boolean {
      if (!this.$route.name) return false;
      return this.$route.meta?.isAuthRoute;
    },
    isDefaultFooter(): boolean {
      if (!this.$route.name) return true;
      return !this.$route.meta?.isSmallFooter;
    },
  },
  async created() {
    if ("serviceWorker" in navigator) {
      // Listen for our custom event from the SW registration
      document.addEventListener("swUpdated", this.updateAvailable, { once: true });
      // Prevent multiple refreshes
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.swRefreshing) return;
        this.swRefreshing = true;
        window.location.reload();
      });
    }
  },
  async mounted() {
    if (this.$appConfig.isEmbed) {
      console.log("pg-embedded-version: ", process.env.VUE_APP_CODE_VERSION);
      document.documentElement.style.overflow = "auto";
    } else {
      console.log(
        `%cVersion: ${process.env.VUE_APP_CODE_VERSION}`,
        "color: green; font-size: 16px"
      );
    }
  },
  methods: {
    updateAvailable(event: any) {
      // Store the SW registration
      this.swRegistration = event.detail;
      this.swUpdateExists = true;
      this.refreshApp();
    },
    refreshApp() {
      this.swUpdateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.swRegistration || !this.swRegistration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this.swRegistration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
  },
});
</script>

<style lang="scss">
@import "@/assets/style/global";
@import "@/assets/style/mixins";

.view-wrapper {
  .view {
    background-color: var(--color-purple-light);
  }
}

.app {
  min-height: calc(100vh);
  background-color: var(--color-purple-light) !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
.pg-embedded {
  background-color: transparent !important;

  .autocomplete-overlay {
    background-color: transparent !important;
  }
}
</style>
